import { useTranslation } from 'react-i18next'

const AppFooter = () => {

  const {t} = useTranslation()

  return <footer className={"bg-white w-full py-8"}>
    <div className={"flex flex-col lg:flex-row items-center justify-center lg:gap-x-8 mb-6 text-primary"}>
      <p><a href={"https://biroad.es/"} target={"_blank"} rel="noreferrer">biroad.es</a></p>
      <p><a href={"https://biroad.es/aviso-legal-2/"} target={"_blank"} rel="noreferrer">{t("footer.avisoLegal")}</a></p>
      <p><a href={"https://biroad.es/politica-de-privacidad/"} target={"_blank"} rel="noreferrer">{t("footer.privacyPolicy")}</a></p>
      <p><a href={"https://biroad.es/politica-de-venta/"} target={"_blank"} rel="noreferrer">{t("footer.sellPolicy")}</a></p>
    </div>
    <div className={"text-center text-sm text-gray-500"}>
      <p>BI-Road &copy;, C/ Uruguai 6, 07010, Palma. NIF 78218484-S</p>
    </div>
  </footer>
}

export default AppFooter