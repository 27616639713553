import AppDateRange from '../forms/AppDateRange'
import { useReservationContext } from '../../context/ReservationProvider'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { addDays } from 'date-fns'
import Select from '../forms/Select'
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import { IoCalendarClearOutline, IoClose } from 'react-icons/io5'
import { useSearchParams } from 'react-router-dom'
import { TiThMenu, TiTimes } from 'react-icons/ti'
import TextInput from '../forms/TextInput'
import { BiMap } from 'react-icons/bi'
import { Tooltip } from 'react-tooltip'

const AppTopBar = ({ fieldsAreEditable = false }) => {

  const {
    t
  } = useTranslation()
  const [locationInputState, setLocationInputState] = useState('')
  const [searchParams] = useSearchParams()
  const [collapsed, setCollapsed] = useState(true)

  const {
    reservationState,
    setReservationState,
  } = useReservationContext()

  const locationRef = useRef()

  useLayoutEffect(() => {
    if (!!locationRef?.current) {
      initAutocomplete()
    }
  }, [
    locationRef?.current
  ])

  useEffect(() => {
    if (searchParams.size > 0) {
      setReservationState(prev => ({
        ...prev,
        dates: [
          !searchParams.has('initial_date') ? prev.dates[0] : new Date(searchParams.get('initial_date')).toISOString(),
          !searchParams.has('final_date') ? prev.dates[1] : new Date(searchParams.get('final_date')).toISOString(),
        ],
        provider_id: !searchParams.has('provider_id') ? prev.provider_id : searchParams.get('provider_id'),
        collect: !searchParams.has('collect') ? prev.collect : searchParams.get('collect'),
        userLocationName: !searchParams.has('userLocationName') ? prev.userLocationName : searchParams.get(
          'userLocationName'),
        userCoordinates: {
          lat: !searchParams.has('lat') ? prev.userCoordinates.lat : searchParams.get('lat'),
          long: !searchParams.has('long') ? prev.userCoordinates.long : searchParams.get('long')
        },
      }))
    }
  }, [searchParams, setReservationState])

  const onChangeDatePicker = useCallback((dates) => {
    if (!!dates) {
      setReservationState(prev => ({
        ...prev,
        dates: [new Date(dates[0]).toISOString(), new Date(dates[1]).toISOString()]
      }))
    }
  }, [setReservationState])

  const onChangeCollect = useCallback((ev) => {
    setReservationState(prev => ({
      ...prev,
      collect: ev.target.value === 'collect'
    }))
  }, [setReservationState])

  const initAutocomplete = useCallback(() => {

    if (!window?.google) {
      return
    }

    const autocompleteField = document.getElementById('location-autocomplete')

    const center = {
      lat: 39.61718925489755,
      lng: 2.993239335380958
    }

    const defaultBounds = {
      north: center.lat + 1,
      south: center.lat - 1,
      east: center.lng + 1,
      west: center.lng - 1,
    }

    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'es' },
      fields: ['geometry', 'name'],
      strictBounds: true,
    }
    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteField, options)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      setReservationState(prev => ({
        ...prev,
        userCoordinates: {
          lat: place?.geometry?.location?.lat(),
          long: place?.geometry?.location?.lng()
        },
        userLocationName: place?.name
      }))
      setLocationInputState('')
    })
  }, [setReservationState])

  return (<header className="shadow-lg bg-white">
    <div className="px-8 flex flex-col lg:flex-row justify-between items-center py-4 lg:py-0 lg:gap-y-4">
      <div className="flex w-full lg:w-auto flex-row lg:flex-col items-center lg:items-start justify-between lg:justify-start">
        <a href="https://biroad.es">
          <img src="/logo.png" alt="Bi Road" height="100"/>
        </a>
        <button className={'lg:hidden'} onClick={() => setCollapsed(prev => !prev)}>
          {collapsed ? <TiThMenu size={36} className={'text-primary'}/> : <TiTimes size={36}
                                                                                   className={'text-primary'}/>}
        </button>
      </div>
      <div className={`flex flex-col lg:flex-row items-center justify-between md-8/12 lg:w-9/12 lg:gap-x-4 w-full transition-all duration-500 ease-in-out lg:max-h-max lg:z-10 ${collapsed ? 'max-h-0 -z-10' : 'max-h-max mt-4 lg:mt-0'}`}>
        <div className={`flex flex-col lg:flex-row items-center justify-between mb-4 lg:mb-0 gap-y-2 w-full xl:w-8/12 lg:gap-x-4`}>
          <TextInput inputRef={locationRef}
                     disabled={!fieldsAreEditable}
                     value={locationInputState || reservationState?.userLocationName}
                     onChange={(ev) => setLocationInputState(ev.target.value)}
                     placeholder={t('placeholders.selectLocation')}
                     inputExtraClasses={`bg-zinc-100 ${!reservationState?.userLocationName && 'border-2 border-primary'} w-full`}
                     containerExtraClasses={`w-full`}
                     icon={<BiMap className={'text-primary'}/>}
                     id="location-autocomplete"/>
          {!fieldsAreEditable && <Tooltip anchorSelect="#location-autocomplete">
            {t('alerts.locationDisabled')}
          </Tooltip>}

          <div className="bg-zinc-100 rounded-md p-2 px-4 w-full text-sm">
            <AppDateRange value={reservationState?.dates}
                          id="reservation-dates"
                          onChange={onChangeDatePicker}
                          disableCalendar={!fieldsAreEditable}
                          disabled={!fieldsAreEditable}
                          minDate={addDays(new Date(), process.env.REACT_APP_MIN_DAYS_RESERVATION || 3)}
                          maxDate={addDays(new Date(), 365)}
                          clearIcon={<IoClose className="text-primary"/>}
                          calendarIcon={<IoCalendarClearOutline className="text-primary"/>}
            />
            {!fieldsAreEditable && <Tooltip anchorSelect="#reservation-dates">
              {t('alerts.datesDisabled')}
            </Tooltip>}

          </div>
          <Select
            id="reservation-collect"
            containerExtraClasses={'w-full'}
            inputExtraClasses={'bg-zinc-100'}
            disabled={!fieldsAreEditable}
            value={!!reservationState?.collect ? 'collect' : 'delivery'}
            placeholder={t('placeholders.collect')}
            onChange={onChangeCollect}
            options={[
              {
                key: 'collect',
                label: t('inputs.collectInStore')
              }, {
                key: 'delivery',
                label: t('inputs.delivery')
              }
            ]}/>
          {!fieldsAreEditable && <Tooltip anchorSelect="#reservation-collect">
            {t('alerts.collectDisabled')}
          </Tooltip>}
        </div>
        <div>
          <LanguageSwitcher/>
        </div>
      </div>
    </div>
  </header>)
}

export default AppTopBar