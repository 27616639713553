function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function calculateTotalPrice(state){
  let price = state?.bikes.reduce((prev, next) => prev + (next.price + next.extras.reduce((
    p,
    n
  ) => p + n.total_price, 0)) + (next?.pedals?.price || 0), 0)
  return price + state?.deliveryPrice
}

export {validateEmail, calculateTotalPrice}