import Card from '../ui/Card'
import Select from '../forms/Select'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { generateBikeBrand, generateBikeRange, generateBikeType } from '../../utils/bikeFilters'
import CheckButton from '../forms/CheckButton'
import { TiFilter } from 'react-icons/ti'

const ReservationBikeFilter = ({
  bikes = [],
  bikeFilter,
  setBikeFilter
}) => {

  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(true)

  const bikeTypeOptions = useMemo(() => {
    if (!!bikes?.length) {
      const options = generateBikeType(bikes)
      return options || []
    }
    return []
  }, [bikes])

  const bikeBrandOptions = useMemo(() => {
    if (!!bikes?.length) {
      const options = generateBikeBrand(bikes)
      return options || []
    }
    return []
  }, [bikes])

  const bikeRangeOptions = useMemo(() => {
    if (!!bikes?.length) {
      const options = generateBikeRange(bikes)
      return options || []
    }
    return []
  }, [bikes])

  const onSelectType = useCallback((ev) => {
    setBikeFilter(prev => ({
      ...prev,
      bike_type_id: ev.target.value
    }))
  }, [setBikeFilter])

  const onSelectBrand = useCallback((ev) => {
    setBikeFilter(prev => ({
      ...prev,
      bike_brand_id: ev.target.value,
    }))
  }, [setBikeFilter])

  const onSelectRange = useCallback((ev) => {
    setBikeFilter(prev => ({
      ...prev,
      range: ev.target.value,
    }))
  }, [setBikeFilter])

  const onChangeElectronicChanges = useCallback((ev) => {
    setBikeFilter(prev => ({
      ...prev,
      electronic_change: !prev.electronic_change,
    }))
  }, [setBikeFilter])

  const onChangeDiskBrake = useCallback((ev) => {
    setBikeFilter(prev => ({
      ...prev,
      disk_brake: !prev.disk_brake,
    }))
  }, [setBikeFilter])

  const onResetFilters = useCallback(() => {
    setBikeFilter({
      bike_type_id: null
    })
  }, [setBikeFilter])

  return <div>
    <button className={"bg-white p-2 rounded-full lg:hidden mr-auto right-0"} onClick={() => setCollapsed(prev => !prev)}>
      <TiFilter size={36} className={!collapsed ? 'text-primary' : 'text-black'} />
    </button>
    <div className={`w-full mb-4 transition-all duration-500 ease-in-out ${collapsed ? 'max-h-0 -z-10 opacity-0 lg:max-h-screen lg:z-10 lg:opacity-100' : 'max-h-max mt-4 lg:mt-0'}`}>
      <Card extraNameCard={`w-full`}>
        <div className="flex flex-col lg:flex-row lg: justify-between gap-y-4 lg:gap-y-0">
          <div className={"flex flex-col lg:flex-row items-center gap-y-2 lg:gap-y-0 lg:gap-x-4"}>
            <Select placeholder={t('labels.bikeType')}
                    value={bikeFilter.bike_type_id}
                    onChange={onSelectType}
                    options={bikeTypeOptions}
                    containerExtraClasses="border rounded-lg lg:w-auto min-w-[120px]"/>

            <Select placeholder={t('labels.bikeBrand')}
                    value={bikeFilter.bike_brand_id}
                    onChange={onSelectBrand}
                    options={bikeBrandOptions}
                    containerExtraClasses="border rounded-lg lg:w-auto min-w-[120px]"/>

            <Select placeholder={t('labels.bikeRange')}
                    value={bikeFilter.range}
                    onChange={onSelectRange}
                    options={bikeRangeOptions.map(o => ({
                      ...o,
                      label: t('labels.' + o.label)
                    }))}
                    containerExtraClasses="border rounded-lg lg:w-auto min-w-[120px]"/>

            <CheckButton label={t('labels.bikeElectronicChanges')}
                         onChange={onChangeElectronicChanges}
                         value={bikeFilter.electronic_change}/>

            <CheckButton label={t('labels.bikeDiskBrake')}
                         onChange={onChangeDiskBrake}
                         value={bikeFilter.disk_brake}/>
          </div>

          <button onClick={onResetFilters} className={'text-primary text-sm border-primary border rounded-lg p-2 px-4'}>{t(
            'actions.deleteFilters')}</button>
        </div>
      </Card>
    </div>
    </div>
}

export default ReservationBikeFilter