const calculateBikePrice = ({
  days = 1,
  priceMatrix = {},
  priceAdditionalDay
}) => {
  let price = 0
  const priceToArray = Object.values(priceMatrix)

  if (!priceToArray?.length) {
    return 0
  }

  const priceInMatrix = priceToArray.find(p => parseInt(p.time) === days)

  if (!!priceInMatrix) {
    price = priceInMatrix?.price
  } else {
    const lastPriceInMatrix = priceToArray[priceToArray?.length - 1]?.price
    const lastDayInMatrix = parseInt(priceToArray[priceToArray?.length - 1]?.time)
    price = lastPriceInMatrix + (priceAdditionalDay * (days - lastDayInMatrix))
  }

  return Number(price)
}

const calculateBikeExtraPrice = ({
  days = 1,
  extra
}) => {
  if (extra?.price_type === 'day') {
    return extra.price * days
  }
  if (extra?.price_type === 'reservation') {
    return extra.price
  }
  return 0
}

const calculateBikeDeliveryPrice = ({distance, priceMatrix = []}) => {
  let price = 0;
  priceMatrix.forEach((p) => {
    if (Number(distance) >= Number(p.distance)) {
      price = p.price;
    }
  });
  return price;
}

export { calculateBikePrice, calculateBikeExtraPrice, calculateBikeDeliveryPrice }