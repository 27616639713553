import AppScaffold from '../../components/ui/AppScaffold'
import ReservationSidebar from '../../components/reservation/ReservationSidebar'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReservationContext } from '../../context/ReservationProvider'
import ReservationBike from '../../components/reservation/ReservationBike'
import ReservationBikeFilter from '../../components/reservation/ReservationBikeFilter'
import { useNavigate } from 'react-router-dom'
import ReservationBreadcrumb from '../../components/breadcrumb/ReservationBreadcrumb'
import { Helmet } from 'react-helmet'
import BikeDistanceRange from '../../components/forms/BikeDistanceRange'
import Card from '../../components/ui/Card'
import { BiMap } from 'react-icons/bi'
import TextInput from '../../components/forms/TextInput'
import { Tooltip } from 'react-tooltip'

const ReservationBikeScreen = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    bikes,
    reservationState,
    setReservationState,
    originalBikeCollection,
    setOriginalBikeCollection,
    bikeFilter,
    setBikeFilter
  } = useReservationContext()

  const [locationInputState, setLocationInputState] = useState('')

  const locationRef = useRef()

  const canContinue = useMemo(() => {
    return !!reservationState?.bikes?.length
  }, [reservationState?.bikes])

  const reservationFieldsEditable = useMemo(() => {
    return reservationState.bikes.length === 0
  }, [reservationState.bikes.length])

  useLayoutEffect(() => {
    if (!!locationRef?.current) {
      initAutocomplete()
    }
  }, [
    locationRef?.current
  ])

  useEffect(() => {
    if (originalBikeCollection === null) {
      if (!!bikes?.length) {
        setOriginalBikeCollection(bikes)
      }
    }
  }, [originalBikeCollection, bikes, setOriginalBikeCollection])

  const onAddBikeSize = useCallback(({
    bike,
    size
  }) => {
    setReservationState(prev => ({
      ...prev,
      bikes: [
        ...prev.bikes, {
          ...bike,
          size: size
        }
      ]
    }))

  }, [setReservationState])

  const onRemoveBikeSize = useCallback(({
    bikeId,
    size
  }) => {

    const bikeToRemoveIndex = reservationState?.bikes?.findIndex(b => b.id === bikeId && b.size === size)

    setReservationState(prev => ({
      ...prev,
      bikes: prev.bikes.filter((b, index) => index !== bikeToRemoveIndex)
    }))
  }, [reservationState?.bikes, setReservationState])

  const initAutocomplete = useCallback(() => {
    const autocompleteField = document.getElementById('location-autocomplete-card')

    const center = {
      lat: 39.61718925489755,
      lng: 2.993239335380958
    }

    const defaultBounds = {
      north: center.lat + 1,
      south: center.lat - 1,
      east: center.lng + 1,
      west: center.lng - 1,
    }

    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'es' },
      fields: ['geometry', 'name'],
      strictBounds: true,
    }
    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteField, options)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      setReservationState(prev => ({
        ...prev,
        userCoordinates: {
          lat: place?.geometry?.location?.lat(),
          long: place?.geometry?.location?.lng()
        },
        userLocationName: place?.name
      }))
      setLocationInputState('')
    })
  }, [setReservationState])

  const onContinue = useCallback(() => {
    navigate('/extras')
  }, [navigate])

  return <AppScaffold reservationFieldsEditable={reservationFieldsEditable}>
    <Helmet>
      <title>{t('titles.bikeScreen')}</title>
    </Helmet>
    <ReservationBreadcrumb/>
    <div className="flex flex-col md:flex-row mb-10 lg:mb-0 md:gap-4 justify-between items-start">
      <main className="mb-4 lg:mb-0 flex-1">
        {!!reservationState?.userLocationName ? <ReservationBikeFilter bikes={originalBikeCollection} bikeFilter={bikeFilter} setBikeFilter={setBikeFilter}/> : false}
        <div className={'block lg:hidden'}>
          <BikeDistanceRange initialValue={bikeFilter.distance_radius}
                             onFinalChange={(values) => setBikeFilter(prev => ({
                               ...prev,
                               distance_radius: values[0]
                             }))}/>
        </div>
        <div className="gap-4 grid grid-cols-1 lg:grid-cols-2 flex-1">
          {!!bikes?.length ? bikes.map(bike => <ReservationBike bike={bike}
                                                                bikeReservedSizes={reservationState?.bikes?.filter(b => b.id === bike.id) || []}
                                                                onRemoveBikeSize={onRemoveBikeSize}
                                                                onAddBikeSize={onAddBikeSize}
                                                                key={bike.id}/>) : false}
        </div>
        {!bikes?.length && !reservationState?.userLocationName ? <Card extraNameCard={'w-full'}>
          <p className={'text-center text-gray-500 mb-6'}>{t('alerts.noBikesNoLocation')}</p>

          <TextInput inputRef={locationRef}
                     value={locationInputState || reservationState?.userLocationName}
                     onChange={(ev) => setLocationInputState(ev.target.value)}
                     placeholder={t('placeholders.selectLocation')}
                     inputExtraClasses={`bg-zinc-100 w-full lg:w-1/2 mx-auto`}
                     containerExtraClasses={`w-full`}
                     icon={<BiMap className={'text-primary'}/>}
                     id="location-autocomplete-card"/>

        </Card> : false}
        {!bikes?.length && !!reservationState?.userLocationName ? <Card extraNameCard={'w-full'}>
          <p className={'text-center text-gray-500'}>{t('alerts.noBikes')}</p>
        </Card> : false}
      </main>
      <aside className="w-full md:w-3/12 lg:sticky top-16">
        <div className={'hidden lg:block'} id={'bike-distance-ranger'}>
          <BikeDistanceRange initialValue={bikeFilter.distance_radius}
                             disabled={!reservationFieldsEditable}
                             onFinalChange={(values) => setBikeFilter(prev => ({
                               ...prev,
                               distance_radius: values[0]
                             }))}/>
          {!reservationFieldsEditable && <Tooltip anchorSelect="#bike-distance-ranger">
            {t('alerts.bikeRangeDisabled')}
          </Tooltip>}
        </div>
        <ReservationSidebar/>
        <div className="fixed lg:relative drop-shadow-lg lg:drop-shadow-none bottom-0 left-0 w-full px-6 lg:px-0 pb-4 lg:pb-0">
          <button disabled={!canContinue}
                  onClick={onContinue}
                  className={`${canContinue ? 'opacity-100' : 'opacity-30'} hover:bg-primary/50 bg-primary font-bold text-white w-full p-2 rounded-lg mt-5`}>{t(
            'actions.continue')}</button>
        </div>
      </aside>
    </div>
  </AppScaffold>
}

export default ReservationBikeScreen