const TextInput = ({
  label = '',
  value = '',
  name = '',
  readonly = false,
  required = false,
  disabled = false,
  onChange = () => {},
  inputExtraClasses = '',
  containerExtraClasses = '',
  inputRef = null,
  errorMessage = '',
  icon = <></>,
  ...props
}) => {

  return <div className={`${containerExtraClasses}`}>
    {!!label ? <label htmlFor={name}
                      className={`font-bold text-sm mb-1 block ${!!errorMessage ? 'text-red-500' : ''}`}>{label} {required ?
      <sup>*</sup> : false}</label> : false}
    <div className={`w-full p-2 text-gray-600 flex flex-row items-center rounded-lg ${inputExtraClasses}`}>
      <input type={'text'}
             ref={inputRef}
             name={name}
             value={value}
             required={required}
             onChange={onChange}
             readOnly={readonly}
             disabled={disabled}
             className={"bg-transparent w-full appearance-none border-0 focus:outline-0"}
             {...props}
      />
      {icon}
    </div>
    {!!errorMessage ? <span className="text-xs text-red-500">{errorMessage}</span> : false}
  </div>

}

export default TextInput