import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Modal = ({
  id,
  visible,
  title,
  closeModal = () => {},
  onClose,
  closable = true,
  bodyComponent = <></>,
  ...props
}) => {
  return <Transition show={visible} as={Fragment}>
    <Dialog open={visible} onClose={() => closable ? onClose() : {}} className="relative z-[99] w-full">
      <Dialog.Backdrop className={'fixed inset-0 bg-black/30 z-[99]'}/>
      <div className={'fixed inset-0 flex w-screen items-center justify-center p-4'}>
        <Transition.Child as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95">
          <Dialog.Panel className="w-full max-w-sm xl:max-w-3xl bg-white p-9 relative rounded shadow">
            <Dialog.Title className={'text-2xl font-medium text-gray-dark dark:text-white mb-4'}>{title}</Dialog.Title>
            {closable ? <button type="button"
                                className="absolute top-4 right-4 text-gray-400 bg-gray-100 hover:bg-gray-300 hover:text-gray-900 rounded-full text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={onClose}>
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"></path>
              </svg>
            </button> : false}
            {bodyComponent}
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
}

export default Modal
