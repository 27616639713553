import ReservationBikeScreen from '../screens/reservation/ReservationBikeScreen'
import ReservationExtrasScreen from '../screens/reservation/ReservationExtrasScreen'
import ReservationDetailsScreen from '../screens/reservation/ReservationDetailsScreen'
import ReservationPaymentScreen from '../screens/reservation/ReservationPaymentScreen'
import ReservationConfirmationScreen from '../screens/reservation/ReservationConfirmationScreen'
import ReservationErrorScreen from '../screens/reservation/ReservationErrorScreen'
import ReservationIframe from '../screens/iframe/ReservationIframe'
import Error404 from '../screens/errors/404'

const routes = [
  {
    path: '/',
    element: <ReservationBikeScreen/>
  },
  {
    path: '/extras',
    element: <ReservationExtrasScreen/>
  },
  {
    path: '/details',
    element: <ReservationDetailsScreen/>
  },
  {
    path: '/payment/:reservation_id',
    element: <ReservationPaymentScreen/>
  },
  {
    path: '/payment-ok',
    element: <ReservationConfirmationScreen/>
  },
  {
    path: '/payment-ko',
    element: <ReservationErrorScreen/>
  },
  {
    path: 'iframe/reservation_form',
    element: <ReservationIframe/>,
  },
  {
    path: '*',
    element: <Error404 />
  }
]

export default routes