const settings = {
  reservation: {
    maxReservedSizes: 6
  },
  ui: {
    colors: {
      primary: "#FBBA18",
      lightGray: "rgb(156, 163, 175)"
    }
  }
}

export default settings;