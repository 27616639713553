import { useRef, useState, useEffect, useCallback } from "react";

const DropDown = ({
  dropdownClassName,
  DropdownMenu = <></>,
  children,
  onDropdownOpen = () => {},
  onDropdownClosed = () => {},
  ...props
}) => {
  const buttonRef = useRef();
  const dropdownRef = useRef();
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({
    top: 0,
    right: 0,
  });

  useEffect(() => {
    document.addEventListener("mousedown", onClickOut);
    calculatePosition();
    return () => {
      document.removeEventListener("mousedown", onClickOut);
    };
  }, []);

  useEffect(() => {
    if (show) {
      onDropdownOpen();
    } else {
      onDropdownClosed();
    }
  }, [show]);

  const calculatePosition = () => {
    const height = buttonRef.current.clientHeight;
    setPosition({ ...position, top: height });
  };

  const handleClick = useCallback((ev) => {
    ev.preventDefault();
    setShow(!show);
  });

  const onClickOut = (ev) => {
    if (dropdownRef.current?.contains(ev.target)) {
      return;
    }
    if (!ev.target.classList?.contains("dropdown-button")) {
      setShow(false);
    }
  };

  return (
    <div className="dropdown-container relative flex flex-col justify-center items-center">
      <button
        className="dropdown-button"
        type="button"
        ref={buttonRef}
        onClick={handleClick}
        {...props}
      >
        {children}
      </button>
      <div
        ref={dropdownRef}
        onClick={() => setShow(!show)}
        className={`${dropdownClassName} ${
          show ? "block" : "hidden"
        } dropdown-menu z-10 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 absolute top-0 right-0`}
        style={{ top: position.top, right: position.right }}
      >
        {DropdownMenu}
      </div>
    </div>
  );
};

export default DropDown;
