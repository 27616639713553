import { useTranslation } from 'react-i18next'
import { useReservationContext } from '../../context/ReservationProvider'
import { useCallback, useLayoutEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsBicycle } from 'react-icons/bs'

const ReservationBreadcrumb = ({ breadcrumbItems = [] }) => {

  const location = useLocation()
  const navigate = useNavigate()
  const {
    breadcrumbState,
    setBreadcrumbState
  } = useReservationContext()
  const { t } = useTranslation()

  useLayoutEffect(() => {
    setBreadcrumbState(prev => prev.map((p, i) => p.path === location.pathname ? {
      ...p,
      active: true,
      clickable: true
    } : p))
  }, [location?.pathname])

  const onClickElement = useCallback((item) => {
    if (!!item?.clickable) {
      navigate(item?.path)
    }
  }, [navigate])

  const BreadcrumbItem = ({
    item,
    active
  }) => {

    return <button onClick={() => onClickElement(item)}
                   className={`flex flex-row items-center ${item?.clickable ? 'cursor-pointer' : 'cursor-default'}`}>
      <span className={`text-lg font-bold mr-3 ${active ? 'text-primary' : 'text-gray-400'}`}>{t(item?.translationKey)}</span>
      <BsBicycle size={20} className={`${active ? 'text-primary' : 'text-gray-400'}`}/>
    </button>
  }

  return <div className={'flex flex-col lg:flex-row lg:items-center gap-x-4 mb-4'}>
    {breadcrumbState.map((item, index) => <BreadcrumbItem key={index} active={item?.active} item={item}/>)}
  </div>

}

export default ReservationBreadcrumb