import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReservationContext } from '../../context/ReservationProvider'
import { calculateBikePrice } from '../../utils/bikePrices'
import settings from '../../settings/settings'
import { BsCaretDownFill } from 'react-icons/bs'
import { Splide, SplideSlide } from '@splidejs/react-splide'

import '@splidejs/react-splide/css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { BiMap } from 'react-icons/bi'
import { FaInfoCircle } from 'react-icons/fa'
import { useAppContext } from '../../context/AppProvider'

const ReservationBike = ({
  bike,
  bikeReservedSizes = [],
  onAddBikeSize = () => {},
  onRemoveBikeSize = () => {},
}) => {

  const { t } = useTranslation()
  const { setGlobalModal } = useAppContext()
  const {
    reservationDays,
    disabledBikes,
    similarBikes = [],
  } = useReservationContext()

  const [openSizes, setOpenSizes] = useState(false)

  const bikePrice = useMemo(() => {
    return calculateBikePrice({
      days: reservationDays,
      priceMatrix: bike?.prices,
      priceAdditionalDay: bike?.price_additional_day
    }) || null
  }, [bike?.prices, bike?.price_additional_day, reservationDays])

  const bikeSelected = useMemo(() => {
    return bikeReservedSizes.find(s => s?.id === bike?.id)
  }, [bike?.id, bikeReservedSizes])

  const bikeDescriptionComponent = useMemo(() => {
    return !!bike?.description ? <div>
      <span dangerouslySetInnerHTML={{ __html: bike?.description }}/>
    </div> : false
  }, [bike?.description])

  const isDisabled = useMemo(() => disabledBikes.includes(bike.id), [bike?.id, disabledBikes])

  const isSimilar = useMemo(() => similarBikes.includes(bike.id), [bike?.id, similarBikes])

  const bikeCanBeDeleted = useMemo(() => {
    return bikeReservedSizes.filter(s => s?.id === bike?.id).length > 0
  }, [bikeReservedSizes, bike?.id])

  useEffect(() => {
    if (!!bikeSelected) {
      setOpenSizes(true)
    } else {
      setOpenSizes(false)
    }
  }, [bikeSelected])

  const onToggleSizes = useCallback(() => {
    if (!openSizes) {
      if (document.getElementById('bike-sizes-' + bike?.id)) {
        document.getElementById('bike-sizes-' + bike.id)
          .scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
      }
    }
    setOpenSizes(prev => !prev)

  }, [bike?.id, openSizes])

  const onClickInfo = useCallback(() => {
    setGlobalModal(prev => ({
      ...prev,
      visible: true,
      title: bike?.brand?.name + ' ' + bike?.model,
      bodyComponent: bikeDescriptionComponent
    }))
  }, [bike?.brand?.name, bike?.model, bikeDescriptionComponent, setGlobalModal])

  const BikeSlider = useMemo(() => {
    if (bike?.images?.length > 1) {
      return <div className={'relative mb-4 h-auto]'}>
        <Splide options={{
          height: 270,
          autoWidth: true
        }}>
          {bike.images.map(image => <SplideSlide key={image} className={'flex items-center'}>
            <LazyLoadImage src={image} alt={bike?.reference} wrapperClassName={'w-full h-auto'}/>
          </SplideSlide>)}
        </Splide>
        <div className="absolute top-0 right-0 text-primary text-3xl font-bold bg-white p-2 rounded-lg">{bikePrice} €</div>
      </div>
    }
    return <div className={`relative flex flex-row justify-center mb-4 h-[270px]`}>
      <LazyLoadImage src={bike?.featured_image}
                     height={270}
                     alt={bike?.reference}
                     wrapperClassName={'mb-2 !h-auto max-w-full'}/>
      <div className="absolute top-0 right-0 text-primary text-3xl font-bold bg-white p-2 rounded-lg">{bikePrice} €</div>
    </div>
  }, [bike?.images, bike?.featured_image, bike?.reference, bikePrice])

  const BikeSizeInput = ({ size }) => {

    const numberOfSizes = useMemo(() => {
      return bikeReservedSizes.filter(s => s?.size === size)?.length
    }, [size])

    const defaultFreePedals = !!bike?.pedals ? Object.values(bike?.pedals || {})?.filter(p => p?.price === 0) : []

    const reducedBike = {
      id: bike.id,
      provider_id: bike.provider_id,
      provider: bike.provider,
      distance: bike.distance,
      price: bikePrice,
      prices: bike.prices,
      price_additional_day: bike.price_additional_day,
      extras: [],
      pedals: !!defaultFreePedals?.length ? defaultFreePedals[0] : {}
    }

    const disableRemove = !numberOfSizes || numberOfSizes === 0
    const disableAdd = numberOfSizes >= settings.reservation.maxReservedSizes

    return <div className="flex flex-row items-center">
      <div className="flex flex-row items-stretch bg-white rounded-lg px-2 py-1 mr-2">
        <button className={`text-base font-bold pr-2 ${disableRemove ? 'opacity-50 pointer-events-none' : 'opacity-100'}`}
                onClick={() => onRemoveBikeSize({
                  bikeId: bike.id,
                  size: size
                })}
                disabled={disableRemove}>-
        </button>
        <span className="font-bold text-xl text-center">{numberOfSizes || 0}</span>
        <button className={`text-base font-bold pl-2 ${disableAdd ? 'opacity-50 pointer-events-none' : 'opacity-100'}`}
                disabled={disableAdd}
                onClick={() => onAddBikeSize({
                  bike: reducedBike,
                  size
                })}>+
        </button>
      </div>
      <p className="font-bold text-sm">{size}</p>
    </div>
  }

  return <div className={`relative`} id={'bike-' + bike?.id}>
    <div className={`p-6 bg-white block relative rounded-t-lg cursor-pointer ${bikeSelected ? 'border-primary border-2' : ''} ${isSimilar ? 'border-green-500 border-2' : ''} ${isDisabled ? 'opacity-50' : 'opacity-100'}`}>
      <div className={'h-6 w-6 absolute border top-5 left-5 rounded-full flex flex-col items-center justify-center border-gray-400 z-20'}
           onClick={onToggleSizes}>
        {bikeSelected ? <div className="bg-primary h-5 w-5 rounded-full"/> : false}
      </div>
      {BikeSlider}

      <div className={'flex flex-row items-center justify-center'}>
        <h3 className="font-bold text-center text-xl">{bike?.brand?.name} {bike?.model}</h3>
        <button onClick={onClickInfo}><FaInfoCircle className={'text-primary ml-1'}/></button>
      </div>

      <h4 className="text-center text-lg uppercase">{bike?.type?.name}</h4>
      <div className={'py-4 flex flex-row justify-center items-center'}>
        <BiMap className={'text-primary mr-1'}/>
        <p className={'text-gray-500'}>{Number(bike?.distance / 1000).toFixed(2)} Km</p>
      </div>
      <div className="grid grid-cols-2 gap-x-4 gap-y-2 leading-tight">
        {!!bike?.frame ? <p><strong>{t('labels.frame')}:</strong> {bike.frame}</p> : false}
        {!!bike?.wheel ? <p><strong>{t('labels.wheel')}:</strong> {bike.wheel}</p> : false}
        {!!bike?.changes ? <p><strong>{t('labels.changes')}:</strong> {bike.changes}</p> : false}
        {!!bike?.electronic_change ? <p><strong>{t('labels.electronic_change')}</strong></p> : false}
        {!!bike?.disk_brake ? <p><strong>{t('labels.disk_brake')}</strong></p> : false}
      </div>
    </div>
    {!isDisabled || bikePrice ? <div className={'bg-primary px-6 py-4 rounded-b-lg'} id={'bike-sizes-' + bike?.id}>
      <div className={'flex-row cursor-pointer flex items-center justify-center'}
           onClick={() => setOpenSizes(prev => !prev)}>
        <span className="text-white block text-center font-bold"
        >{!openSizes ? t('actions.selectSizes') : t('cards.bike.availableSizes')}</span>
        <BsCaretDownFill className="text-white ml-2"/>
      </div>
      {openSizes ? <div className={'grid grid-cols-2 gap-4 pt-4'}>
        {bike?.sizes?.map((size, index) => <BikeSizeInput key={index} size={size}/>)}
      </div> : false}
    </div> : false}
    {isDisabled ?
      <div className={'absolute top-1/2 left-1/2 drop-shadow-lg -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg'}>
        <p className={'font-bold text-sm text-center mb-2'}>{t('alerts.bikeDisabledTitle')}</p>
        <p className={'text-sm text-center'}>{t('alerts.bikeDisabledDescription')}</p>
      </div> : false}
  </div>
}

export default ReservationBike