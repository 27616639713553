import AppScaffold from '../../components/ui/AppScaffold'
import Card from '../../components/ui/Card'
import { useTranslation } from 'react-i18next'
import ReservationService from '../../services/ReservationService'
import { useQuery } from '@tanstack/react-query'
import { FiAlertTriangle } from 'react-icons/fi'

const ReservationErrorScreen = () => {

  const { t } = useTranslation()
  const service = new ReservationService()

  const { data: parametersResponse } = useQuery({
    queryKey: ['parameters'],
    queryFn: () => service.sendRedsysParameters({ parameters: window.location.search }),
    enabled: !!window?.location?.search
  })

  return <AppScaffold titleClassName={"text-center"} extraNameBody={"flex flex-col items-center"}>
    <Card title={t('cards.payment_error.title')} subtitle={t('cards.payment_error.subtitle')}>
      {!!parametersResponse?.error ?
        <div className={'bg-red-200 border-red-300 border text-center flex flex-row items-center justify-center p-4 text-red-500 rounded-xl'}><FiAlertTriangle className={"text-red-500 mr-2"} size={32} />{parametersResponse.error}</div> : false}
      {!!parametersResponse?.reservation &&
        <a href={'/payment/' + parametersResponse?.reservation?.id} className={"bg-primary hover:bg-primary/50 inline-flex w-auto font-bold text-white p-2 px-4 rounded-lg mt-5"}>{t('actions.tryPayment')}</a>}
    </Card>
  </AppScaffold>
}

export default ReservationErrorScreen