import { Range } from 'react-range'
import { useMemo, useState } from 'react'

const BikeDistanceRange = ({ initialValue, onFinalChange = () => {}, disabled = false}) => {

  const [rangeValues, setRangeValues] = useState([initialValue || 40000])

  const renderValue = useMemo(() => {
    return Number.isNaN(rangeValues[0]) ? 0 : Number(rangeValues[0] / 1000).toFixed(0)
  }, [rangeValues])

  return <div className={'mb-6'}>
    <Range min={10000}
           max={100000}
           disabled={disabled}
           step={0.5}
           values={rangeValues}
           onFinalChange={(values) => onFinalChange(values)}
           onChange={(value) => setRangeValues(value)}
           renderTrack={({
             props,
             children
           }) => (
             <div
               {...props}
               className={"h-1.5 rounded-lg bg-primary w-full"}
             >
               {children}
             </div>
           )}
           renderThumb={({ props }) => (
             <div
               {...props}
               className={"h-6 w-6 bg-white border-2 border-primary rounded-full"}
             >
               <div className={"absolute w-20 text-xs -top-12 -left-8 bg-white text-primary border-primary border-2 p-2 rounded-xl font-bold"}> { "< " + renderValue} Km</div>
             </div>
           )}/>
  </div>
}

export default BikeDistanceRange