import i18next from 'i18next'

const appRequest = async ({
  uri = '',
  method = 'GET',
  body = null
}) => {

  const apiUrl = process.env.REACT_APP_API_URL

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': i18next?.language || 'es'
  }

  try {
    const response = await fetch(apiUrl + uri, {
      method: method,
      headers: headers,
      body: !!body ? JSON.stringify(body) : null
    })

    if (!response.ok) {
      const error = await response.json()
      return Promise.reject(error)
    }

    const json = await response.json()
    return Promise.resolve(json)

  } catch (e) {
    return Promise.reject(e)
  }
}

export default appRequest;