import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { addDays, differenceInCalendarDays, differenceInDays, isValid, parseISO } from 'date-fns'
import { useQuery } from '@tanstack/react-query'
import ReservationService from '../services/ReservationService'
import { calculateTotalPrice } from '../utils/functions'
import { useAppContext } from './AppProvider'
import { useTranslation } from 'react-i18next'
import { calculateBikeDeliveryPrice, calculateBikePrice } from '../utils/bikePrices'

const ReservationContext = createContext()

export function useReservationContext () {
  return useContext(ReservationContext)
}

const reservationInitialState = {
  dates: [
    addDays(new Date(), 3).toISOString(), addDays(new Date(), 5).toISOString(),
  ],
  collect: true,
  collectTime: '08:00',
  deliveryPrice: 0,
  returnTime: '20:00',
  userLocationName: null,
  userCoordinates: {
    lat: null,
    long: null
  },
  bikes: [],
  userDetails: {
    name: '',
    email: '',
    nif: '',
    phone: '',
    street: '',
    number: '',
    town: '',
    province: '',
    countryCode: 'ES',
    cp: ''
  },
  deliveryAddress: {
    addressName: '',
    street: '',
    number: '',
    town: '',
    cp: ''
  },
  comments: '',
  total: 0,
  bypassDifferentProviders: false
}

const bikeFilterInitialState = {
  bike_type_id: undefined,
  bike_brand_id: undefined,
  electronic_change: undefined,
  disk_brake: undefined,
  range: undefined,
  distance_radius: 40000
}

const breadcrumbItems = [
  {
    path: '/',
    clickable: true,
    active: false,
    translationKey: 'breadcrumbs.bikes'
  },
  {
    path: '/extras',
    clickable: false,
    active: false,
    translationKey: 'breadcrumbs.extras'
  },
  {
    path: '/details',
    clickable: false,
    active: false,
    translationKey: 'breadcrumbs.details'
  },
]

const reservationService = new ReservationService()

const ReservationProvider = ({ children }) => {

  const { setGlobalModal } = useAppContext()

  const { t } = useTranslation()

  const [reservationState, setReservationState] = useState(window.localStorage.getItem('biRoad_reservation_state') ? JSON.parse(
    window.localStorage.getItem('biRoad_reservation_state')) : reservationInitialState)
  const [breadcrumbState, setBreadcrumbState] = useState(window.localStorage.getItem('biRoad_breadcrumb_state') ? JSON.parse(
    window.localStorage.getItem('biRoad_breadcrumb_state')) : breadcrumbItems)
  const [originalBikeCollection, setOriginalBikeCollection] = useState(null)
  const [disabledBikes, setDisabledBikes] = useState([])
  const [similarBikes, setSimilarBikes] = useState([])
  const [bikeFilter, setBikeFilter] = useState(window.localStorage.getItem('biRoad_filter_state') ? JSON.parse(
    window.localStorage.getItem('biRoad_filter_state')) : bikeFilterInitialState)

  const { data: bikes } = useQuery({
    queryKey: ['bikes', bikeFilter, reservationState?.dates, reservationState.userCoordinates, reservationState.collect],
    queryFn: () => reservationService.getBikesByDistance({
      coordinates: reservationState.userCoordinates,
      filters: bikeFilter,
      dates: reservationState.dates,
      collect: reservationState.collect
    }),
    enabled: !!reservationState?.userCoordinates?.lat && !!reservationState?.userCoordinates?.long && !!reservationState.dates
  })

  const reservationDays = useMemo(() => {
    if (!reservationState?.dates[0]) {
      return 0
    }
    const initialDateFromIso = parseISO(reservationState?.dates[0])
    const finalDateFromIso = parseISO(reservationState?.dates[1])
    if (isValid(initialDateFromIso)) {
      const diff = differenceInCalendarDays(finalDateFromIso, initialDateFromIso)
      return diff + 1 // All days should be included
    }
    return 0
  }, [reservationState?.dates])

  useEffect(() => {
    if (originalBikeCollection === null && !!bikes?.length) {
      setOriginalBikeCollection(bikes)
    }
  }, [bikes, originalBikeCollection])

  useEffect(() => {
    if (!!bikes?.length) {
      checkProviderDeliveryPrice()
      checkDifferentProviders()
    }
  }, [reservationState?.bikes, bikes?.length, reservationState.collect])

  useEffect(() => {
    setReservationState(prev => ({
      ...prev,
      total: calculateTotalPrice(prev)
    }))
  }, [reservationState?.deliveryPrice, reservationState?.bikes])

  useEffect(() => {
    window.localStorage.setItem('biRoad_reservation_state', JSON.stringify(reservationState))
  }, [reservationState])

  useEffect(() => {
    window.localStorage.setItem('biRoad_breadcrumb_state', JSON.stringify(breadcrumbState))
  }, [breadcrumbState])

  useEffect(() => {
    window.localStorage.setItem('biRoad_filter_state', JSON.stringify(bikeFilter))
  }, [bikeFilter])

  // useEffect(() => {
  //   if (!!reservationState?.bikes?.length) {
  //     updateReservedBikePrices()
  //   }
  // }, [reservationDays, reservationState?.bikes?.length])

  const checkDifferentProviders = useCallback(() => {
    const providers = reservationState?.bikes?.reduce((prev, next) => !prev.includes(next.provider_id) ? [
      ...prev,
      next.provider_id
    ] : prev, [])

    if (!reservationState.collect && !!providers?.length) {
      setDisabledBikes(bikes.filter(b => !providers.includes(b.provider_id)).map(b => b.id))
    } else {
      setDisabledBikes([])
    }

    if (providers?.length > 1) {

      if (!reservationState.collect) {
        setGlobalModal(prev => ({
          ...prev,
          visible: true,
          closable: false,
          bodyComponent: <DifferentProviderDeliveryAlert/>
        }))
        return
      }

      // ask question
      if (!reservationState.bypassDifferentProviders) {
        // open modal
        setGlobalModal(prev => ({
          ...prev,
          visible: true,
          closable: false,
          bodyComponent: <DifferentProviderAlert/>
        }))

      }
    }
  }, [
    reservationState?.bikes,
    reservationState?.collect,
    reservationState?.bypassDifferentProviders,
    bikes,
    setGlobalModal
  ])

  const checkProviderDeliveryPrice = useCallback(() => {
    if (!reservationState.collect) {
      const providerIds = reservationState?.bikes?.reduce((prev, next) => !prev.includes(next) ? [
        ...prev,
        next.provider_id
      ] : prev, [])

      if (!providerIds?.length) {
        setReservationState(prev => ({
          ...prev,
          deliveryPrice: 0
        }))
        return
      }

      const distanceToProvider = reservationState.bikes[0]?.distance

      providerIds.forEach(id => {
        const provider = bikes?.find(b => b.provider_id === id)?.provider
        const deliveryPriceValues = Object.values(provider?.delivery_prices || {})
        console.log("deliveryPriceValues", deliveryPriceValues)
        if(!!deliveryPriceValues?.length){
          const deliveryPrice = calculateBikeDeliveryPrice({
            distance: distanceToProvider,
            priceMatrix: deliveryPriceValues
          })
          console.log("deliveryPrice", deliveryPrice, distanceToProvider, deliveryPriceValues)
          if(deliveryPrice > 0){
            setReservationState(prev => ({
              ...prev,
              deliveryPrice: Number(deliveryPrice.toFixed(2))
            }))
          }
        }
      })
    } else {
      setReservationState(prev => ({
        ...prev,
        deliveryPrice: 0
      }))
    }
  }, [bikes, reservationState?.bikes, reservationState?.collect])

  const emptyReservationState = useCallback(() => {
    setReservationState(reservationInitialState)
    setBreadcrumbState(breadcrumbItems)
    setOriginalBikeCollection(null)
  }, [])

  const onBypassDifferentProviders = useCallback(() => {
    setReservationState(prev => ({
      ...prev,
      bypassDifferentProviders: true
    }))

    setGlobalModal(prev => ({
      ...prev,
      visible: false,
      closable: false,
      bodyComponent: null
    }))
  }, [setGlobalModal])

  const showSimilarBikes = useCallback(async () => {

    try {
      if (!reservationState?.bikes?.length) return

      const firstProvider = reservationState.bikes[0]?.provider_id
      const lastBikeAdded = reservationState?.bikes?.[reservationState?.bikes?.length - 1]

      if (!!lastBikeAdded?.id) {
        const similarBikesId = await reservationService.getSimilarBikes({
          providerId: firstProvider,
          referenceBikeId: lastBikeAdded?.id
        })

        if (!!similarBikesId?.length) {
          const domElement = document.getElementById('bike-' + similarBikesId[0])
          if (!!domElement) {
            domElement.scrollIntoView({ behavior: 'smooth' })
          }
        }

        setSimilarBikes(similarBikesId)
      }

      // delete last bike from the state

      setReservationState(prev => ({
        ...prev,
        bikes: prev.bikes.filter((b, i) => i !== (prev.bikes.length - 1))
      }))

      setGlobalModal(prev => ({
        ...prev,
        visible: false,
        closable: false,
        bodyComponent: null
      }))
    } catch (e) {
      console.error(e)
    }
    // call API to receive a list of similar bikes to the last one added

  }, [reservationState?.bikes, setGlobalModal])

  const onCancelDeliveryCollection = useCallback(() => {
    setReservationState(prev => ({
      ...prev,
      collect: true
    }))
    setGlobalModal(prev => ({
      ...prev,
      visible: false,
      closable: false,
      bodyComponent: null
    }))
  }, [setGlobalModal])

  const onContinueChangeDeliveryCollection = useCallback(() => {
    const firstProviderId = reservationState?.bikes?.[0]?.provider_id
    setReservationState(prev => ({
      ...prev,
      bikes: prev.bikes.filter(b => b.provider_id !== firstProviderId)
    }))
    setGlobalModal(prev => ({
      ...prev,
      visible: false,
      closable: false,
      bodyComponent: null
    }))
  }, [reservationState?.bikes, setGlobalModal])

  const updateReservedBikePrices = useCallback(() => {
    const bikeToUpdate = reservationState.bikes.map(bike => {
      return {
        ...bike,
        price: calculateBikePrice({
          days: reservationDays,
          priceMatrix: bike.prices,
          priceAdditionalDay: bike.price_additional_day
        })
      }
    })
    setReservationState((prev) => ({
      ...prev,
      bikes: bikeToUpdate
    }))
  }, [reservationDays, reservationState?.bikes])

  const DifferentProviderAlert = () => {
    return <div>
      <p className={'text-center font-bold text-lg mb-4'}>{t('alerts.differentProviderTitle')}</p>
      <p className={'text-center'} dangerouslySetInnerHTML={{ __html: t('alerts.differentProviderDescription') }}/>
      <div className={'mt-4 flex flex-col lg:flex-row justify-between lg:gap-x-4 gap-y-2'}>
        <button onClick={showSimilarBikes}
                className={'bg-white border-2 border-primary font-bold text-primary w-full p-2 rounded-lg'}>{t(
          'actions.showSimilarBikes')}</button>
        <button onClick={onBypassDifferentProviders}
                className={'bg-primary font-bold text-white w-full p-2 rounded-lg'}>{t(
          'actions.continueWithDifferentProviders')}</button>
      </div>
    </div>
  }

  const DifferentProviderDeliveryAlert = () => {
    return <div>
      <p className={'text-center font-bold text-lg mb-4'}>{t('alerts.differentProviderTitle')}</p>
      <p className={'text-center'}
         dangerouslySetInnerHTML={{ __html: t('alerts.differentProviderDeliveryDescription') }}/>
      <div className={'mt-4 flex flex-col lg:flex-row justify-between lg:gap-x-4 gap-y-2'}>
        <button onClick={onCancelDeliveryCollection}
                className={'bg-white border-2 border-primary font-bold text-primary w-full p-2 rounded-lg'}>{t(
          'actions.cancelCollectChange')}</button>
        <button onClick={onContinueChangeDeliveryCollection}
                className={'bg-primary font-bold text-white w-full p-2 rounded-lg'}>{t(
          'actions.continueWithDifferentProvidersDelivery')}</button>
      </div>
    </div>
  }

  return <ReservationContext.Provider value={{
    bikes,
    reservationState,
    reservationDays,
    setReservationState,
    originalBikeCollection,
    setOriginalBikeCollection,
    bikeFilter,
    setBikeFilter,
    breadcrumbState,
    setBreadcrumbState,
    emptyReservationState,
    disabledBikes,
    similarBikes
  }}>
    {children}
  </ReservationContext.Provider>
}

export default ReservationProvider