import { createContext, useContext, useState } from 'react'
import AppModal from '../components/ui/AppModal'

const AppContext = createContext()

export function useAppContext () {
  return useContext(AppContext)
}

const globalModalInitialValue = {
  visible: false,
  title: '',
  bodyComponent: null,
  closable: true
}

const AppProvider = ({ children }) => {

  const [globalModal, setGlobalModal] = useState(globalModalInitialValue)

  return <AppContext.Provider value={{ setGlobalModal }}>
    {children}
    <AppModal
      id="globalModal"
      visible={globalModal?.visible}
      title={globalModal?.title}
      bodyComponent={globalModal.bodyComponent}
      closable={globalModal.closable}
      onClose={() => setGlobalModal(globalModalInitialValue)}/>
  </AppContext.Provider>
}

export default AppProvider