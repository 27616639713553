const Card = ({
  title = '',
  subtitle = '',
  extraNameCard = '',
  extraNameBody = '',
  titleClassName = '',
  subtitleClassName = '',
  children
}) => {
  return <div className={`bg-white p-4 rounded-lg ${extraNameCard}`}>
    {title ? <h3 className={`${titleClassName} text-lg font-bold`}>{title}</h3> : false}
    {subtitle ? <p className={`text-md text-gray-500 ${subtitleClassName}`}>{subtitle}</p> : false}
    <div className={`py-4 ${extraNameBody}`}>
      {children}
    </div>
  </div>
}

export default Card