import Card from '../ui/Card'
import { useTranslation } from 'react-i18next'
import { useReservationContext } from '../../context/ReservationProvider'
import { Fragment, useCallback, useMemo } from 'react'
import { format, isValid, parseISO } from 'date-fns'
import { Popover } from '@headlessui/react'
import { MdInfoOutline } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import { useLocation } from 'react-router-dom'

const ReservationSidebar = () => {

  const {
    originalBikeCollection,
    reservationState,
    setReservationState,
    providers,
    reservationDays
  } = useReservationContext()
  const { t } = useTranslation()
  const location = useLocation()

  const canDeleteBike = useMemo(() => {
    return location.pathname === '/'
  }, [location.pathname])

  const formatReservationDates = useMemo(() => {
    if (isValid(reservationState?.dates[0])) {
      return format(reservationState?.dates[0], 'dd/MM/yyyy') + ' - ' + format(reservationState?.dates[1], 'dd/MM/yyyy')
    } else {
      const initialDateFromIso = parseISO(reservationState?.dates[0])
      const finalDateFromIso = parseISO(reservationState?.dates[1])
      if (isValid(initialDateFromIso)) {
        return format(initialDateFromIso, 'dd/MM/yyyy') + ' - ' + format(finalDateFromIso, 'dd/MM/yyyy')
      }
    }
  }, [reservationState?.dates])

  const reservedBikes = useMemo(() => {
    let reserved = []

    reservationState.bikes.forEach(reservedBike => {
      if (!!reserved?.length && reserved.find(r => r?.bike?.id === reservedBike?.id)) {
        reserved = reserved.map(r => r?.bike?.id === reservedBike?.id ? {
          ...r,
          sizes: {
            ...r.sizes,
            [reservedBike.size]: r.sizes[reservedBike.size] ? r.sizes[reservedBike.size] + 1 : 1
          },
          extras: [...r.extras, reservedBike.extras],
          price: r.price + reservedBike.price,
          extrasTotal: r.extrasTotal + reservedBike.extras.reduce((p, n) => p + n.total_price, 0),
          pedalsTotal: r.pedalsTotal + reservedBike?.pedals?.price || 0
        } : r)
      } else {
        reserved.push({
          bike: originalBikeCollection?.find(bi => bi.id === reservedBike.id),
          price: reservedBike.price,
          sizes: { [reservedBike.size]: 1 },
          extras: reservedBike.extras,
          extrasTotal: reservedBike.extras.reduce((p, n) => p + n.total_price, 0),
          pedalsTotal: reservedBike?.pedals?.price || 0
        })
      }
    })
    return reserved
  }, [originalBikeCollection, reservationState?.bikes])

  const extrasTooltip = useCallback(({ extras }) => {
    return <ul>
      {extras?.map((extra, index) => <li key={index}
                                         className="list-disc text-sm ml-2">{extra?.extra?.name} {extra?.total_price} &euro;</li>)}
    </ul>

  }, [])

  const onDeleteBike = useCallback((bikeIndex) => {
    setReservationState(prevState => {
      const newBikes = prevState.bikes.filter((_, index) => index !== bikeIndex)
      return {
        ...prevState,
        bikes: newBikes
      }
    })
  }, [setReservationState])

  return <Card title={t('cards.reservation.title')} titleClassName="text-primary">
    <div className="mb-4">
      <p><strong>{t('labels.dates')}</strong></p>
      <p>{formatReservationDates} {reservationDays > 0 ? ('(' + reservationDays + ' ' + t('labels.days', { count: reservationDays })) + ')' : false}</p>
    </div>
    <div className="mb-4">
      <p><strong>{t('labels.collect')}</strong></p>
      <div className="flex flex-row justify-between mb-1">
        <p>{reservationState?.collect ? t('labels.collectInStore') : t('labels.delivery')}</p>
        {reservationState?.deliveryPrice ?
          <p className={'text-xl text-primary font-bold text-right whitespace-nowrap'}> + {reservationState.deliveryPrice} &euro;</p> : null}
      </div>
    </div>
    {!!providers?.length && !!reservationState?.provider_id ? <div className="mb-4">
      <p><strong>{t('labels.collectPoint')}</strong></p>
      <p>{providers.find(provider => provider.id === reservationState?.provider_id)?.name}</p>
    </div> : false}

    {!!reservedBikes?.length && <Fragment>
      <hr className={'border-primary my-6'}/>

      {reservedBikes.map((reserved, index) => <div key={index} className="mb-4">
        <div className="flex flex-row justify-between mb-1">
          <div className={"flex flex-row items-start"}>
            {canDeleteBike && <button className={"cursor-pointer hover:text-primary mr-1"} onClick={() => onDeleteBike(index)}>
              <IoMdTrash size={22} />
            </button>}
            <p className="text-lg mr-2"><strong>{reserved?.bike?.brand?.name} {reserved?.bike?.model}</strong></p>
          </div>
          <p className={'text-xl text-primary font-bold text-right whitespace-nowrap'}>{reserved?.price} &euro;</p>
        </div>
        <div className="mb-1">
          {Object.keys(reserved.sizes).map((key => <p key={key}><strong>{reserved.sizes[key]}</strong> {key}</p>))}
        </div>
        {reserved?.extrasTotal > 0 ? <Popover className={"relative"}>
          <div className="flex flex-row justify-between mb-1">
            <Popover.Button>
              <p className="font-bold flex flex-row items-center">{t('labels.extras')} <MdInfoOutline className={"ml-1"} /></p>
            </Popover.Button>
            <Popover.Panel className="absolute z-10 bg-white p-4 shadow-lg rounded-md top-0 mt-5">
               {extrasTooltip({ extras: reserved.extras })}
            </Popover.Panel>
            <p className={'text-primary font-bold text-right whitespace-nowrap'}>{reserved.extrasTotal} &euro;</p>
          </div>
        </Popover> : false}
        {reserved?.pedalsTotal > 0 ? <div className="flex flex-row justify-between mb-1">
          <p className="font-bold">{t('labels.pedals')} </p>
          <p className={'text-primary font-bold text-right whitespace-nowrap'}>{reserved.pedalsTotal} &euro;</p>
        </div> : false}
      </div>)}

    </Fragment>}

    {reservationState?.total > 0 ? <Fragment>
      <hr className={'border-primary my-6'}/>

      <div className="flex flex-row justify-between mb-1">
        <p className="text-lg"><strong>{t('labels.totalReservationPrice')}</strong></p>
        <p className={'text-2xl text-primary font-bold text-right'}>{reservationState?.total || 0} &euro;</p>
      </div>
    </Fragment> : false}

  </Card>
}
export default ReservationSidebar