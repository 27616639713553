import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import TextInput from '../../components/forms/TextInput'
import { useTranslation } from 'react-i18next'
import { addDays } from 'date-fns'
import { IoCalendarClearOutline, IoClose } from 'react-icons/io5'
import AppDateRange from '../../components/forms/AppDateRange'
import { useSearchParams } from 'react-router-dom'
import i18next from 'i18next'

const ReservationIframe = () => {

  const { t } = useTranslation()
  const locationRef = useRef()
  const [searchParams, setSearchParams] = useSearchParams()

  const [locationInputState, setLocationInputState] = useState('')
  const [iframeState, setIframeState] = useState({
    initial_date: addDays(new Date(), 3).toISOString(),
    final_date: addDays(new Date(), 5).toISOString(),
    userLocationName: '',
    lat: '',
    long: '',
    lang: 'es'
  })

  const canSubmit = useMemo(() => {
    return!!iframeState?.lat &&!!iframeState?.long && !!iframeState?.initial_date &&!!iframeState?.final_date
  },[iframeState?.final_date, iframeState?.initial_date, iframeState?.lat, iframeState?.long])

  useEffect(() => {
    if (searchParams.has('lang')) {
      i18next.changeLanguage(searchParams.get('lang') || 'es')
      setIframeState(prev => ({
        ...prev,
        lang: searchParams.get('lang')
      }))
    }
  }, [searchParams])

  useLayoutEffect(() => {
    if (!!locationRef?.current) {
      initAutocomplete()
    }

    const globalModal = document.getElementById("modal-globalModal")
    if(!!globalModal){
      globalModal.remove()
    }
  }, [
    locationRef?.current
  ])

  const initAutocomplete = useCallback(() => {

    if(!window?.google?.maps){
      return;
    }

    const autocompleteField = document.getElementById('location-autocomplete')

    const center = {
      lat: 39.61718925489755,
      lng: 2.993239335380958
    }

    const defaultBounds = {
      north: center.lat + 1,
      south: center.lat - 1,
      east: center.lng + 1,
      west: center.lng - 1,
    }

    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'es' },
      fields: ['geometry', 'name'],
      strictBounds: true,
    }
    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteField, options)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      setIframeState(prev => ({
        ...prev,
        lat: place?.geometry?.location?.lat(),
        long: place?.geometry?.location?.lng(),
        userLocationName: place?.name
      }))
      setLocationInputState('')
    })
  }, [])

  const onChangeDatePicker = useCallback((dates) => {
    if (!!dates) {
      setIframeState(prev => ({
        ...prev,
        initial_date: new Date(dates[0]).toISOString(),
        final_date: new Date(dates[1]).toISOString()
      }))
    }
  }, [setIframeState])

  const onSubmit = useCallback(() => {
    const parameters = new URLSearchParams(iframeState)
    window.open(process.env.REACT_APP_FRONT_URL + '?q&' + parameters.toString(), '_blank')
  }, [iframeState])

  return <div className={'w-full'}>
    <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 items-center justify-center rounded-md bg-white p-4 gap-x-4">
      <TextInput inputRef={locationRef}
                 value={locationInputState || iframeState?.userLocationName}
                 onChange={(ev) => setLocationInputState(ev.target.value)}
                 placeholder={t('placeholders.selectLocation')}
                 inputExtraClasses="bg-zinc-100 rounded-md min-w-[250px]"
                 containerExtraClasses={"flex-1 w-full lg:w-auto"}
                 id="location-autocomplete"/>
      <div className="bg-zinc-100 rounded-md p-2 px-4 text-sm flex-1 w-full lg:w-auto">
        <AppDateRange value={[iframeState?.initial_date, iframeState?.final_date]}
                      onChange={onChangeDatePicker}
                      minDate={addDays(new Date(), 3)}
                      maxDate={addDays(new Date(), 365)}
                      clearIcon={<IoClose className="text-primary"/>}
                      calendarIcon={<IoCalendarClearOutline className="text-primary"/>}
        />
      </div>
      <button className={`bg-primary p-2 rounded-md flex-2 w-full lg:w-auto text-white ${!canSubmit && 'opacity-50'}`} disabled={!canSubmit} onClick={onSubmit}>
        {t('actions.bookNow')}
      </button>
    </div>
  </div>
}

export default ReservationIframe