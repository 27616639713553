import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import Select from '../forms/Select'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ReservationBikeExtras = ({
  reservedBike,
  bike,
  index,
  onToggleExtra,
  onSelectPedals
}) => {

  const { t } = useTranslation()

  const bikePedalOptions = useMemo(() => {
    if (!!Object.values(bike?.pedals || {})?.length) {
      return Object.values(bike.pedals).map(pedal => ({
        key: pedal.name,
        label: pedal.name ? pedal.name + (pedal.price > 0 ? ' +' + pedal.price + ' €' : '') : t('labels.defaultPedals'),
      }))
    }
    return []
  }, [t, bike?.pedals])

  const BikeExtra = ({ extra }) => {

    const isAdded = reservedBike?.extras?.find(e => e.bike_extra_id === extra.bike_extra_id)

    return <button className="flex flex-row text-left cursor-pointer" onClick={() => onToggleExtra({
      bikeIndex: index,
      extra: extra
    })}>
      <div className="h-6 w-6 bg-white rounded-full border border-gray-300 mr-2 flex flex-col items-center justify-center">
        <div className={`h-5 w-5 rounded-full ${isAdded ? 'bg-gray-700' : ''}`}/>
      </div>
      <label className="font-bold leading-tight text-sm lg:w-10/12 cursor-pointer">{extra?.extra?.name}
        <span className="ml-1 text-xs whitespace-nowrap">+{extra?.price} &euro;/{t('labels.' + extra?.price_type)}</span></label>
    </button>
  }

  return <div className="bg-white border border-primary mb-4 rounded-lg">
    <div className="flex flex-col lg:flex-row items-center p-6">
      <LazyLoadImage src={bike?.featured_image}
                     height={270}
                     alt={bike?.reference}
                     wrapperClassName={'h-[270px] mr-4'}/>
      <div className="lg:w-5/12">
        <p className="font-bold text-xl mb-4 text-gray-900">{reservedBike?.size}</p>
        <p className="font-bold text-lg"><strong>{bike?.brand?.name} {bike?.model}</strong></p>
        {!!bike?.frame ? <p><strong>{t('labels.frame')}:</strong> {bike.frame}</p> : false}
        {!!bike?.wheel ? <p><strong>{t('labels.wheel')}:</strong> {bike.wheel}</p> : false}
        {!!bike?.changes ? <p><strong>{t('labels.changes')}:</strong> {bike.changes}</p> : false}
        {!!bike?.electronic_change ? <p><strong>{t('labels.electronic_change')}</strong></p> : false}
        {!!bike?.disk_brake ? <p><strong>{t('labels.disk_brake')}</strong></p> : false}
      </div>
      <div className="lg:w-2/12 lg:self-start pt-4 lg:pt-0">
        <p className="text-center font-bold lg:text-right text-primary text-2xl">{reservedBike?.price} &euro;</p>
      </div>
    </div>
    <div className="bg-primary p-6 rounded-b-lg lg:gap-4 flex flex-col lg:flex-row">
      <div className="lg:w-9/12 mb-4 lg:mb-0">
        <p className="text-white font-bold mb-4">{t('labels.addSomeExtras')}</p>
        <div className="grid lg:grid-cols-3 gap-x-4 gap-y-2">
          {!!Object.values(bike?.rich_extras || {})?.length ? Object.values(bike.rich_extras)
            .map(extra => <BikeExtra key={extra.bike_extra_id} extra={extra}/>) : false}
        </div>
      </div>
      {!!bikePedalOptions?.length ? <div className="lg:wd-3/12">
        <p className="text-white font-bold mb-4">{t('labels.selectPedals')}</p>
        <Select options={bikePedalOptions}
                value={reservedBike?.pedals?.name}
                placeholder={t('labels.selectPedals')}
                onChange={(ev) => onSelectPedals({
                  bikeIndex: index,
                  pedals: Object.values(bike?.pedals).find(p => p.name === ev.target.value)
                })}
                inputExtraClasses={'bg-white'}/>
      </div> : false}
    </div>
  </div>
}

export default ReservationBikeExtras