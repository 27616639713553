import appRequest from '../utils/request'

export default class ReservationService {

  async getProviders ({ coordinates }) {
    let uri = '/v1/reservation/providers?q'
    if (!!coordinates?.long) {
      uri += '&coordinates=' + coordinates?.lat + ',' + coordinates?.long
    }

    return appRequest({ uri: uri }).then((result) => {
      return Promise.resolve(result.data)
    }).catch((e) => {
      return Promise.reject(e)
    })
  }

  async getBikesByDistance ({
    coordinates = {},
    filters = {},
    dates = [],
    collect = true
  }) {
    let uri = '/v1/reservation/bikes?q'

    uri += '&start_date=' + dates[0]
    uri += '&end_date=' + dates[1]
    uri += '&collect=' + (collect ? '1' : '0')

    if (!!coordinates?.long) {
      uri += '&coordinates=' + coordinates?.lat + ',' + coordinates?.long
    }

    if (!!filters?.distance_radius) {
      uri += '&distance_radius=' + filters.distance_radius
    }

    if (!!filters.bike_type_id) {
      uri += '&bike_type_id=' + filters.bike_type_id
    }

    if (!!filters.bike_brand_id) {
      uri += '&bike_brand_id=' + filters.bike_brand_id
    }

    if (!!filters.range) {
      uri += '&range=' + filters.range
    }

    if (!!filters.electronic_change) {
      uri += '&electronic_change=' + filters.electronic_change
    }

    if (!!filters.disk_brake) {
      uri += '&disk_brake=' + filters.disk_brake
    }

    return appRequest({ uri: uri }).then((result) => {
      return Promise.resolve(result.data)
    }).catch((e) => { return Promise.reject(e)})
  }

  async getSimilarBikes ({
    providerId,
    referenceBikeId
  }) {
    return appRequest({ uri: '/v1/reservation/bikes-similar/' + providerId + '/referenceId/' + referenceBikeId })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((e) => { return Promise.reject(e)})
  }

  async createReservation ({ data }) {
    return appRequest({
      uri: '/v1/reservation/create',
      method: 'post',
      body: { reservation_data: JSON.stringify(data) }
    }).then((result) => {
      return Promise.resolve(result.data)
    }).catch((e) => { return Promise.reject(e)})
  }

  async getPaymentAndRedirection ({ reservationId }) {
    return appRequest({
      uri: '/v1/reservation/payment/' + reservationId,
      method: 'get'
    }).then((result) => {
      return Promise.resolve(result.data)
    }).catch((e) => { return Promise.reject(e)})
  }

  async sendRedsysParameters ({ parameters }) {
    return appRequest({
      uri: '/v1/reservation/redsys-parameters/' + parameters,
      method: 'get'
    }).then((result) => {
      return Promise.resolve(result.data)
    }).catch((e) => { return Promise.reject(e)})
  }

}