import AppScaffold from '../../components/ui/AppScaffold'
import { useTranslation } from 'react-i18next'
import ReservationService from '../../services/ReservationService'
import { useQuery } from '@tanstack/react-query'
import Card from '../../components/ui/Card'
import { useReservationContext } from '../../context/ReservationProvider'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const ReservationConfirmationScreen = () => {

  const {
    reservationState,
    emptyReservationState
  } = useReservationContext()

  const { t } = useTranslation()
  const service = new ReservationService()

  const { data: parametersResponse } = useQuery({
    queryKey: ['parameters'],
    queryFn: () => service.sendRedsysParameters({ parameters: window.location.search }),
    enabled: !!window?.location?.search,
  })

  useEffect(() => {
    setTimeout(() => {
      emptyReservationState()
    }, 8000)
  }, [emptyReservationState, parametersResponse])

  return <AppScaffold>
    <Helmet>
      <title>{t('titles.paymentConfirmedScreen')}</title>
    {/*  {reservationState?.total > 0 && !!parametersResponse?.reservation?.number && <script>{`*/}
    {/*  gtag('set', 'user_data', {"email" : ${parametersResponse?.reservation?.email}, "phone_number" : ${parametersResponse?.reservation?.phone}});*/}
    {/*    gtag('event', 'conversion', {*/}
    {/*      'send_to': 'AW-16495335509/DXD1CNvT3aMZENWwy7k9',*/}
    {/*      'value': ${reservationState?.total},*/}
    {/*      'currency': 'EUR',*/}
    {/*      'transaction_id': ${parametersResponse?.reservation?.number},*/}
    {/*    });*/}
    {/*`}</script>}*/}
    </Helmet>
    <Card title={t('cards.payment_ok.title')}
          titleClassName={'text-center'}
          extraNameBody={'flex flex-col items-center'}>
      <div className={'bg-green-200 border-green-300 border text-center flex flex-row items-center justify-center p-4 text-green-600 rounded-xl'}>{t(
        'alerts.paymentSuccessful',
        { number: parametersResponse?.reservation?.number })}</div>
      <a href="https://biroad.es"
         className={'bg-primary hover:bg-primary/50 inline-flex w-auto font-bold text-white p-2 px-4 rounded-lg mt-5'}>{t('actions.backToWebsite')}</a>
    </Card>
  </AppScaffold>
}

export default ReservationConfirmationScreen