import AppScaffold from '../../components/ui/AppScaffold'
import Card from '../../components/ui/Card'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Error404 = () => {

  const { t } = useTranslation()

  return <AppScaffold>
    <Card title={t('cards.404.title')}
          subtitleClassName={'text-center mb-4'}
          titleClassName={'text-center mb-2'}
          extraNameBody={"flex-row flex items-center justify-center"}
          subtitle={t('cards.404.subtitle')}>

      <Link to={"/"} className={"bg-primary hover:bg-primary/50 w-auto font-bold text-white p-2 px-4 rounded-lg mt-5"}>{t("actions.backStart")}</Link>
    </Card>
  </AppScaffold>
}

export default Error404