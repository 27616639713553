const CheckButton = ({
  label = '',
  value = false,
  onChange = () => {}
}) => {
  return <div className="w-full lg:w-auto">
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="hidden"
          checked={value}
          onChange={onChange}
        />
        <div className={`toggle-checkbox w-10 h-5  rounded-full shadow-inner ${value ? 'bg-primary' : 'bg-gray-200'} `}></div>
        <div
          className={`toggle-handle w-5 h-5 absolute top-0 left-0 bg-white rounded-full shadow-md transform transition-transform ${value ? 'translate-x-5' : ''}`}
        ></div>
      </div>
      <div className="ml-2 text-sm text-gray-600">{label}</div>
    </label>
  </div>
}

export default CheckButton