import { BsCaretDownFill } from 'react-icons/bs'
import { useRef } from 'react'

const Select = ({
  options = [],
  label = '',
  value = '',
  placeholder = '',
  required = false,
  readonly = false,
  disabled = false,
  onChange = () => {},
  inputExtraClasses = '',
  containerExtraClasses = '',
  errorMessage = '',
  id = ''
}) => {

  const selectRef = useRef()

  return <div className={`${containerExtraClasses} w-full`}>
    {!!label ? <label className={`font-bold text-sm mb-1 block ${!!errorMessage ? 'text-red-500' : ''}`}>{label} {required ? <sup>*</sup> : false}</label> : false}
    <div className={`text-sm md:text-base text-gray-600 rounded-lg items-center flex w-full bg-transparent relative cursor-pointer ${inputExtraClasses}`}>
      <select id={id} disabled={disabled} ref={selectRef} value={value} onChange={onChange} className={`p-2 px-4 z-10 w-10/12 appearance-none block bg-transparent text-sm`}>
        {placeholder ? <option hidden value={""}>{placeholder}</option> : false}
        {options.map(option => <option key={option.key} value={option?.key}>{option?.label}</option>)}
      </select>
      <BsCaretDownFill className="text-primary absolute top-1/2 -translate-y-1/2 right-2 z-[1]" onClick={() => {
        selectRef?.current?.click()
      }}/>
    </div>
    {!!errorMessage ? <span className="text-xs text-red-500">{errorMessage}</span> : false}
  </div>
}

export default Select