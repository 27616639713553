const generateBikeType = (bikes = []) => {
  return bikes.reduce((prev, next, index, array) => {
    return !!prev.find(p => p.key === next.type.id) ? prev : [...prev, { key: next.type.id, label: next.type.name }]
  }, [])
}

const generateBikeBrand = (bikes = []) => {
  return bikes.reduce((prev, next, index, array) => {
    return !!prev.find(p => p.key === next.brand.id) ? prev : [...prev, { key: next.brand.id, label: next.brand.name }]
  }, [])
}

const generateBikeRange = (bikes = []) => {

  return bikes.reduce((prev, next, index, array) => {
    return !!prev.find(p => p.key === next.range) ? prev : [...prev, { key: next.range, label: next.range }]
  }, [])
}

export { generateBikeType, generateBikeBrand, generateBikeRange }