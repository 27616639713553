const TextArea = ({
  label = '',
  value = '',
  name = '',
  readonly = false,
  required = false,
  disabled = false,
  onChange = () => {},
  inputExtraClasses = '',
  containerExtraClasses = '',
  inputRef = null,
  ...props
}) => {

  return <div className={`${containerExtraClasses}`}>
    {!!label ? <label htmlFor={name} className="font-bold text-sm mb-1 block">{label} {required ?
      <sup>*</sup> : false}</label> : false}
    <textarea
      ref={inputRef}
      name={name}
      value={value}
      required={required}
      onChange={onChange}
      className={`w-full p-2 text-gray-600 rounded-lg ${inputExtraClasses}`}
      readOnly={readonly}
      disabled={disabled}
      {...props}
    >{value}</textarea>
  </div>

}

export default TextArea