import AppScaffold from '../../components/ui/AppScaffold'
import ReservationSidebar from '../../components/reservation/ReservationSidebar'
import { useReservationContext } from '../../context/ReservationProvider'
import ReservationBikeExtras from '../../components/reservation/ReservationBikeExtras'
import { useCallback, useMemo } from 'react'
import { calculateBikeExtraPrice } from '../../utils/bikePrices'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReservationBreadcrumb from '../../components/breadcrumb/ReservationBreadcrumb'
import { Helmet } from 'react-helmet'

const ReservationExtrasScreen = () => {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    bikes,
    reservationState,
    setReservationState,
    reservationDays
  } = useReservationContext()

  const canContinue = useMemo(() => {
    return true
  }, [])

  const onToggleExtra = useCallback(({
    bikeIndex,
    extra
  }) => {
    const extraExists = !!reservationState?.bikes[bikeIndex]?.extras?.find(e => e.bike_extra_id === extra.bike_extra_id)
    if (extraExists) {
      setReservationState(prev => ({
        ...prev,
        bikes: prev.bikes.map((b, i) => i === bikeIndex ? ({
          ...b,
          extras: b.extras.filter(e => e.bike_extra_id !== extra.bike_extra_id)
        }) : b)
      }))
    } else {

      const extraTotalPrice = calculateBikeExtraPrice({
        days: reservationDays,
        extra
      })

      setReservationState(prev => ({
        ...prev,
        bikes: prev.bikes.map((b, i) => i === bikeIndex ? ({
          ...b,
          extras: [
            ...b.extras, {
              ...extra,
              total_price: extraTotalPrice
            }
          ]
        }) : b)
      }))
    }
  }, [reservationDays, reservationState?.bikes, setReservationState])

  const onSelectPedals = useCallback(({
    bikeIndex,
    pedals
  }) => {
    setReservationState(prev => ({
      ...prev,
      bikes: prev.bikes.map((b, i) => i === bikeIndex ? ({
        ...b,
        pedals: pedals
      }) : b)
    }))
  }, [setReservationState])

  const onContinue = useCallback(() => {
    navigate('/details')
  }, [navigate])

  return <AppScaffold>
    <Helmet>
      <title>{t('titles.extrasScreen')}</title>
    </Helmet>
    <ReservationBreadcrumb/>
    <div className="flex flex-col md:flex-row md:gap-4 justify-between items-start mb-10 lg:mb-0">
      <main className="mb-4 lg:mb-0 flex-1">
        <div className="flex-1">
          {!!reservationState?.bikes?.length ? reservationState.bikes.map((bike, index) =>
            <ReservationBikeExtras key={index}
                                   reservedBike={bike}
                                   index={index}
                                   onToggleExtra={onToggleExtra}
                                   onSelectPedals={onSelectPedals}
                                   bike={bikes?.find(b => b.id === bike.id)}/>) : false}
        </div>
      </main>
      <aside className="w-full md:w-4/12 lg:sticky top-2">
        <ReservationSidebar/>
        <div className="fixed lg:relative drop-shadow-lg lg:drop-shadow-none bottom-0 left-0 w-full px-6 lg:px-0 pb-4 lg:pb-0">
          <button disabled={!canContinue}
                  onClick={onContinue}
                  className={`${canContinue ? 'opacity-100' : 'opacity-30'} hover:bg-primary/50 bg-primary font-bold text-white w-full p-2 rounded-lg mt-5`}>{t(
            'actions.continue')}</button>
        </div>
      </aside>
    </div>
  </AppScaffold>
}

export default ReservationExtrasScreen