import AppTopBar from './AppTopBar'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import AppFooter from './AppFooter'

const AppScaffold = ({ children, reservationFieldsEditable = false }) => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return <div className="bg-zinc-100 text-gray-700 min-h-screen flex flex-col justify-between">
    <Helmet titleTemplate={"Bi Road - %s"} />
    <AppTopBar fieldsAreEditable={reservationFieldsEditable}/>
    <main className={'container max-w-screen-2xl mx-auto py-10 px-6 flex-1'}>
      {children}
    </main>
    <AppFooter />
  </div>
}

export default AppScaffold