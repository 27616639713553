import AppScaffold from '../../components/ui/AppScaffold'
import ReservationSidebar from '../../components/reservation/ReservationSidebar'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useReservationContext } from '../../context/ReservationProvider'
import { useTranslation } from 'react-i18next'
import Card from '../../components/ui/Card'
import TextInput from '../../components/forms/TextInput'
import Select from '../../components/forms/Select'
import TextArea from '../../components/forms/TextArea'
import { calculateTotalPrice, validateEmail } from '../../utils/functions'
import municipis from '../../utils/municipis'
import ReservationService from '../../services/ReservationService'
import { useMutation } from '@tanstack/react-query'
import ReservationBreadcrumb from '../../components/breadcrumb/ReservationBreadcrumb'
import { Helmet } from 'react-helmet'
import { countries } from '../../utils/countries'

const service = new ReservationService()

const ReservationDetailsScreen = () => {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    reservationState,
    setReservationState,
  } = useReservationContext()

  const [errors, setErrors] = useState({})

  const {
    mutateAsync: createReservationAsync,
    isLoading: isCreatingReservationLoading
  } = useMutation({ mutationFn: (variables) => service.createReservation({ data: variables.data }) })

  const canContinue = useMemo(() => {
    return !!reservationState?.userLocationName && !!reservationState?.bikes?.length
  }, [reservationState])

  const getProvidersOpeningTime = useCallback((providers) => {
    const hours = []
    providers.forEach(provider => {
      const hour = !!provider?.opening_time ? Number(provider.opening_time.split(':')[0]) : 8
      hours.push(hour)
    })
    return Math.max(...hours)
  }, [])

  const getProvidersClosingTime = useCallback((providers) => {
    const hours = []
    providers.forEach(provider => {
      const hour = !!provider?.closing_time ? Number(provider.closing_time.split(':')[0]) : 20
      hours.push(hour)
    })
    return Math.min(...hours)
  }, [])

  const collectTimeOptions = useMemo(() => {
    const timeList = []
    const openingHour = getProvidersOpeningTime(reservationState.bikes.map(bike => bike.provider))
    const closingHour = getProvidersClosingTime(reservationState.bikes.map(bike => bike.provider))
    for (let hour = openingHour; hour < closingHour; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        timeList.push(time)
      }
    }
    return timeList.map(t => ({
      key: t,
      label: t
    }))
  }, [getProvidersClosingTime, getProvidersOpeningTime, reservationState?.bikes])

  const updateUserDetails = useCallback((key, value) => {
    setReservationState(prev => ({
      ...prev,
      userDetails: {
        ...prev.userDetails,
        [key]: value
      }
    }))
  }, [setReservationState])

  const updateUserDeliveryAddress = useCallback((key, value) => {
    setReservationState(prev => ({
      ...prev,
      deliveryAddress: {
        ...prev.deliveryAddress,
        [key]: value
      }
    }))
  }, [setReservationState])

  const validateState = useCallback(() => {
    setErrors({})

    if (!reservationState?.userDetails?.name) {
      setErrors(prev => ({
        ...prev,
        name: t('errors.nameRequired')
      }))
      return false
    }
    if (!reservationState?.userDetails?.email) {
      setErrors(prev => ({
        ...prev,
        email: t('errors.emailRequired')
      }))
      return false
    }
    if (!validateEmail(reservationState?.userDetails?.email)) {
      setErrors(prev => ({
        ...prev,
        email: t('errors.emailNotValid')
      }))
      return false
    }
    if (!reservationState?.userDetails?.phone) {
      setErrors(prev => ({
        ...prev,
        phone: t('errors.phoneRequired')
      }))
      return false
    }
    if (reservationState.total >= 400) {
      if (!reservationState?.userDetails?.nif) {
        setErrors(prev => ({
          ...prev,
          nif: t('errors.nifRequired')
        }))
        return false
      }
      if (!reservationState?.userDetails?.street) {
        setErrors(prev => ({
          ...prev,
          street: t('errors.streetRequired')
        }))
        return false
      }
      if (!reservationState?.userDetails?.town) {
        setErrors(prev => ({
          ...prev,
          town: t('errors.townRequired')
        }))
        return false
      }
      if (!reservationState?.userDetails?.cp) {
        setErrors(prev => ({
          ...prev,
          cp: t('errors.cpRequired')
        }))
        return false
      }
      if (!reservationState?.userDetails?.province) {
        setErrors(prev => ({
          ...prev,
          province: t('errors.provinceRequired')
        }))
        return false
      }
      if (!reservationState?.userDetails?.countryCode) {
        setErrors(prev => ({
          ...prev,
          countryCode: t('errors.countryCodeRequired')
        }))
        return false
      }
    }
    if (reservationState.collect) {
      if (reservationState.collectTime > reservationState.returnTime) {
        setErrors(prev => ({
          ...prev,
          collectTime: t('errors.returnTimeBeforeCollectTime')
        }))
        return false
      }
    } else {
      if (!reservationState?.deliveryAddress?.addressName) {
        setErrors(prev => ({
          ...prev,
          delivery_addressName: t('errors.delivery_addressName')
        }))
        return false
      }
      if (!reservationState?.deliveryAddress?.street) {
        setErrors(prev => ({
          ...prev,
          delivery_street: t('errors.delivery_street')
        }))
        return false
      }
      if (!reservationState?.deliveryAddress?.town) {
        setErrors(prev => ({
          ...prev,
          delivery_town: t('errors.delivery_town')
        }))
        return false
      }
      if (!reservationState?.deliveryAddress?.cp) {
        setErrors(prev => ({
          ...prev,
          delivery_cp: t('errors.delivery_cp')
        }))
        return false
      }
    }

    return true

  }, [
    reservationState?.userDetails?.name,
    reservationState?.userDetails?.email,
    reservationState?.userDetails?.phone,
    reservationState?.userDetails?.nif,
    reservationState?.userDetails?.street,
    reservationState?.userDetails?.town,
    reservationState?.userDetails?.cp,
    reservationState?.userDetails?.province,
    reservationState?.userDetails?.countryCode,
    reservationState.total,
    reservationState.collect,
    reservationState.collectTime,
    reservationState.returnTime,
    reservationState?.deliveryAddress?.addressName,
    reservationState?.deliveryAddress?.street,
    reservationState?.deliveryAddress?.town,
    reservationState?.deliveryAddress?.cp,
    t
  ])

  const onContinue = useCallback(async () => {
    const validates = validateState()
    if (!validates) {
      return
    }

    try {
      const body = {
        ...reservationState,
        total: calculateTotalPrice(reservationState)
      }

      if (!isCreatingReservationLoading) {
        const { id } = await createReservationAsync({ data: body })
        navigate('/payment/' + id)
      }

    } catch (e) {
      if (!!e?.errors) {
        if (!!e?.errors['userDetails.name']) {
          setErrors(prev => ({
            ...prev,
            name: e.errors['userDetails.name']
          }))
        }
        if (!!e?.errors['userDetails.email']) {
          setErrors(prev => ({
            ...prev,
            email: e.errors['userDetails.email']
          }))
        }
        if (!!e?.errors['userDetails.phone']) {
          setErrors(prev => ({
            ...prev,
            phone: e.errors['userDetails.phone']
          }))
        }
        if (!!e?.errors['userDetails.nif']) {
          setErrors(prev => ({
            ...prev,
            nif: e.errors['userDetails.nif']
          }))
        }
        if (!!e?.errors['userDetails.addressName']) {
          setErrors(prev => ({
            ...prev,
            addressName: e.errors['userDetails.addressName']
          }))
        }
        if (!!e?.errors['userDetails.street']) {
          setErrors(prev => ({
            ...prev,
            street: e.errors['userDetails.street']
          }))
        }
        if (!!e?.errors['userDetails.town']) {
          setErrors(prev => ({
            ...prev,
            town: e.errors['userDetails.town']
          }))
        }
        if (!!e?.errors['userDetails.cp']) {
          setErrors(prev => ({
            ...prev,
            town: e.errors['userDetails.cp']
          }))
        }
        if (!!e?.errors['userDetails.province']) {
          setErrors(prev => ({
            ...prev,
            province: e.errors['userDetails.province']
          }))
        }
        if (!!e?.errors['userDetails.countryCode']) {
          setErrors(prev => ({
            ...prev,
            countryCode: e.errors['userDetails.countryCode']
          }))
        }
      }
    }
  }, [validateState, reservationState, isCreatingReservationLoading, createReservationAsync, navigate])

  return <AppScaffold>
    <Helmet>
      <title>{t('titles.detailsScreen')}</title>
    </Helmet>
    <ReservationBreadcrumb/>
    <div className="flex flex-col md:flex-row md:gap-4 justify-between items-start mb-10 lg:mb-0">
      <main className="mb-4 lg:mb-0 flex-1">
        <Card title={t('cards.details.title')} subtitle={t('cards.details.subtitle')}>
          <div className="mb-6">
            <h3 className="font-bold text-lg mb-4">{t('labels.contactDetails')}</h3>
            <div className="grid md:grid-cols-2 gap-4">
              <TextInput value={reservationState?.userDetails?.name}
                         required
                         name={'name'}
                         onChange={(ev) => updateUserDetails('name', ev.target.value)}
                         label={t('labels.fullName')}
                         placeholder={t('labels.fullName')}
                         errorMessage={errors?.name}
                         inputExtraClasses="border"/>

              <TextInput value={reservationState?.userDetails?.email}
                         required
                         name={'email'}
                         onChange={(ev) => updateUserDetails('email', ev.target.value)}
                         label={t('labels.email')}
                         placeholder={t('labels.email')}
                         type="email"
                         errorMessage={errors?.email}
                         inputExtraClasses="border"/>

              <TextInput value={reservationState?.userDetails?.phone}
                         required
                         name={'phone'}
                         onChange={(ev) => updateUserDetails('phone', ev.target.value)}
                         label={t('labels.phone')}
                         placeholder={t('labels.phone')}
                         type="text"
                         errorMessage={errors?.phone}
                         inputExtraClasses="border"/>

              {reservationState.total > 400 ? <Fragment>
                <TextInput value={reservationState?.userDetails?.nif}
                           required
                           name={'nif'}
                           onChange={(ev) => updateUserDetails('nif', ev.target.value)}
                           label={t('labels.nif')}
                           placeholder={t('labels.nif')}
                           type="text"
                           errorMessage={errors?.nif}
                           inputExtraClasses="border"/>

                <TextInput value={reservationState?.userDetails?.street}
                           required
                           onChange={(ev) => updateUserDetails('street', ev.target.value)}
                           label={t('labels.street')}
                           placeholder={t('labels.street')}
                           type="text"
                           name={'street'}
                           errorMessage={errors?.street}
                           inputExtraClasses="border"/>

                <TextInput value={reservationState?.userDetails?.number}
                           onChange={(ev) => updateUserDetails('number', ev.target.value)}
                           label={t('labels.number')}
                           placeholder={t('labels.number')}
                           type="text"
                           name={'addressNumber'}
                           errorMessage={errors?.number}
                           inputExtraClasses="border"/>

                <TextInput value={reservationState?.userDetails?.town}
                           onChange={(ev) => updateUserDetails('town', ev.target.value)}
                           label={t('labels.town')}
                           placeholder={t('labels.town')}
                           type="text"
                           name={'town'}
                           errorMessage={errors?.town}
                           required
                           inputExtraClasses="border"/>

                <TextInput value={reservationState?.userDetails?.cp}
                           required
                           name={'cp'}
                           onChange={(ev) => updateUserDetails('cp', ev.target.value)}
                           label={t('labels.cp')}
                           placeholder={t('labels.cp')}
                           type="text"
                           errorMessage={errors?.cp}
                           inputExtraClasses="border"/>

                <TextInput value={reservationState?.userDetails?.province}
                           onChange={(ev) => updateUserDetails('province', ev.target.value)}
                           label={t('labels.province')}
                           placeholder={t('labels.province')}
                           type="text"
                           name={'province'}
                           errorMessage={errors?.province}
                           inputExtraClasses="border"/>

                <Select value={reservationState?.userDetails?.countryCode}
                        required
                        onChange={(ev) => updateUserDetails('countryCode', ev.target.value)}
                        label={t('labels.countryCode')}
                        placeholder={t('labels.countryCode')}
                        name={'countryCode'}
                        options={Object.keys(countries).map((c) => ({ key: c, label: countries[c] }))}
                        errorMessage={errors?.countryCode}
                        inputExtraClasses="border"/>

              </Fragment> : false}
            </div>
          </div>

          <hr className="border-primary my-6"/>

          <div>
            <h3 className="font-bold text-lg mb-4">{t('labels.collectDetails')}</h3>
            {reservationState?.collect ? <div className="grid md:grid-cols-2 gap-4 mb-4">
              <Select options={collectTimeOptions}
                      label={t('labels.collectTime')}
                      placeholder={t('labels.collectTime')}
                      inputExtraClasses={'border'}
                      value={reservationState?.collectTime}
                      errorMessage={errors?.collectTime}
                      onChange={(ev) => setReservationState(prev => ({
                        ...prev,
                        collectTime: ev.target.value
                      }))}/>

              <Select options={collectTimeOptions}
                      label={t('labels.returnTime')}
                      placeholder={t('labels.returnTime')}
                      inputExtraClasses={'border'}
                      value={reservationState?.returnTime}
                      onChange={(ev) => setReservationState(prev => ({
                        ...prev,
                        returnTime: ev.target.value
                      }))}/>
            </div> : false}

            {!reservationState?.collect ? <div className="grid md:grid-cols-2 gap-4 mb-4">
              <TextInput value={reservationState?.deliveryAddress?.addressName}
                         required
                         onChange={(ev) => updateUserDeliveryAddress('addressName', ev.target.value)}
                         label={t('labels.addressName')}
                         placeholder={t('labels.addressName')}
                         type="text"
                         name={'delivery_addressName'}
                         errorMessage={errors?.delivery_addressName}
                         inputExtraClasses="border"/>

              <TextInput value={reservationState?.deliveryAddress?.street}
                         required
                         onChange={(ev) => updateUserDeliveryAddress('street', ev.target.value)}
                         label={t('labels.street')}
                         placeholder={t('labels.street')}
                         type="text"
                         name={'delivery_street'}
                         errorMessage={errors?.delivery_street}
                         inputExtraClasses="border"/>

              <TextInput value={reservationState?.deliveryAddress?.number}
                         onChange={(ev) => updateUserDeliveryAddress('number', ev.target.value)}
                         label={t('labels.number')}
                         placeholder={t('labels.number')}
                         type="text"
                         name={'delivery_number'}
                         errorMessage={errors?.delivery_number}
                         inputExtraClasses="border"/>

              <Select value={reservationState?.deliveryAddress?.town}
                      required
                      onChange={(ev) => updateUserDeliveryAddress('town', ev.target.value)}
                      label={t('labels.town')}
                      placeholder={t('labels.town')}
                      name={'delivery_town'}
                      options={municipis.map(m => ({
                        key: m,
                        label: m
                      }))}
                      errorMessage={errors?.delivery_town}
                      inputExtraClasses="border"/>

              <TextInput value={reservationState?.deliveryAddress?.cp}
                         required
                         name={'delivery_cp'}
                         onChange={(ev) => updateUserDeliveryAddress('cp', ev.target.value)}
                         label={t('labels.cp')}
                         placeholder={t('labels.cp')}
                         type="text"
                         errorMessage={errors?.delivery_cp}
                         inputExtraClasses="border"/>
            </div> : false}

            <div>
              <TextArea label={t('labels.comments')}
                        placeholder={t('placeholders.comments')}
                        onChange={(ev) => setReservationState(prev => ({
                          ...prev,
                          comments: ev.target.value
                        }))}
                        value={reservationState?.comments}
                        inputExtraClasses="border"/>
            </div>
          </div>
        </Card>
      </main>
      <aside className="w-full md:w-4/12 lg:sticky top-2">
        <ReservationSidebar/>
        <div className="fixed lg:relative drop-shadow-lg lg:drop-shadow-none bottom-0 left-0 w-full px-6 lg:px-0 pb-4 lg:pb-0">
          <button disabled={!canContinue || isCreatingReservationLoading}
                  onClick={onContinue}
                  className={`${canContinue ? 'opacity-100' : 'opacity-30'} hover:bg-primary/50 bg-primary font-bold text-white w-full p-2 rounded-lg mt-5`}>{t(
            'actions.continue')}</button>
        </div>
      </aside>
    </div>
  </AppScaffold>
}

export default ReservationDetailsScreen