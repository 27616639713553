import Dropdown from './Dropdown'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCaretDownFill } from 'react-icons/bs'

const LanguageSwitcher = () => {

  const {
    t,
    i18n
  } = useTranslation()

  const onChangeLanguage = useCallback(async (key) => {
    await i18n.changeLanguage(key)
  }, [i18n])

  const DropdownMenu = () => {
    return <div>
      <ul>
        {['es', 'ca', 'en', 'de'].map(locale => <li key={locale} className="p-2 hover:bg-red-50">
          <button className="block w-full text-left" onClick={() => onChangeLanguage(locale)}>{t('locales.' + locale)}</button>
        </li>)}
      </ul>
    </div>
  }

  return <div className="h-full">
    <Dropdown DropdownMenu={<DropdownMenu/>}>
      <div className="flex items-center">
        {t('locales.' + i18n.language)}
        <BsCaretDownFill className="ml-2" />
      </div>
    </Dropdown>
  </div>
}

export default LanguageSwitcher