import AppScaffold from '../../components/ui/AppScaffold'
import { useParams } from 'react-router-dom'
import Card from '../../components/ui/Card'
import ReservationSidebar from '../../components/reservation/ReservationSidebar'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import ReservationService from '../../services/ReservationService'
import { useCallback } from 'react'
import { useReservationContext } from '../../context/ReservationProvider'

const service = new ReservationService()

const ReservationPaymentScreen = () => {

  const { t } = useTranslation()
  const { reservation_id } = useParams()

  const {
    reservationState
  } = useReservationContext()

  const {
    data: reservation,
    isLoading: isReservationLoading
  } = useQuery({
    queryKey: ['reservationPayment', reservation_id],
    queryFn: () => service.getPaymentAndRedirection({ reservationId: reservation_id }),
    enabled: !!reservation_id
  })

  const onClickPay = useCallback(() => {
    if (!!reservation?.redsysForm) {
      const form = document.getElementById('redsys_form')
      if (!!form) {
        form.submit()
      }
    }
  }, [reservation?.redsysForm])

  return <AppScaffold>
    <Helmet>
      <title>{t('titles.paymentScreen')}</title>
    </Helmet>
    <div className="flex flex-col md:flex-row md:gap-4 justify-between items-start">
      <main className="mb-4 lg:mb-0 flex-1">
        <Card title={t('cards.payment.title')} subtitle={t('cards.payment.subtitle')}>
          <button onClick={onClickPay}
                  disabled={!reservation?.redsysForm || isReservationLoading}
                  className={'bg-primary hover:bg-primary/50 font-bold text-white w-full p-2 rounded-lg mt-5'}>{t('actions.pay')}</button>
          <div id={'redsys-form-container'} dangerouslySetInnerHTML={{ __html: reservation?.redsysForm }}/>
        </Card>
      </main>
      <aside className="md:w-5/12">
        <ReservationSidebar/>
      </aside>
    </div>
  </AppScaffold>
}

export default ReservationPaymentScreen